let __STORED_LOCALE = null

export function getCurrentLocale() {
    if (__STORED_LOCALE) {
        return __STORED_LOCALE
    } else {
        const locale = document.documentElement.getAttribute('lang') || 'de'
        __STORED_LOCALE = locale
        return locale
    }
}

// reusable function to listen for clicks outside of dom element
export const onClickOutsideOf = (element, cb) => {
    const outsideClickListener = event => {
        if (!element.contains(event.target)) {
            cb()
            removeClickListener()
        }
    }

    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener)
    }

    document.addEventListener('click', outsideClickListener)
}

// IE user agent sniffing (I know, gross)
export const detectIE = () => {
    const sAgent = window.navigator.userAgent
    const idx = sAgent.indexOf('MSIE')

    // If IE, return version number.
    if (idx > 0) {
        return parseInt(sAgent.substring(idx + 5, sAgent.indexOf('.', idx)), 10)
    }

    // If IE 11 then look for Updated user agent string.
    else if (!!navigator.userAgent.match(/Trident\/7\./)) {
        return 11
    }

    return false
}