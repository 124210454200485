const SELECTORS = {
    testimonialsList: '.js-testimonials',
    testimonial: '[data-testimonial-id]',
    logosList: '.js-testimonials-logos',
    logoButton: '[data-show-testimonial]'
}

class Testimonials {
    classes = {
        active: 'is-active'
    }

    elements = {
        testimonialsList: null,
        testimonials: null,
        logosList: null,
        logos: null
    }

    constructor(testimonialsList) {
        this.elements.testimonialsList = testimonialsList
        this.elements.logosList =
            this.elements.testimonialsList.parentElement.querySelector(
                SELECTORS.logosList
            )

        this.elements.testimonials = Array.from(
            this.elements.testimonialsList.querySelectorAll(
                SELECTORS.testimonial
            )
        )
        this.elements.logos = Array.from(
            this.elements.logosList.querySelectorAll(SELECTORS.logoButton)
        )

        this.elements.logos.forEach((logo) =>
            logo.addEventListener('click', this.handleLogoClick.bind(this))
        )
    }

    handleLogoClick(e) {
        e.preventDefault()
        this.showTestimonial(e.currentTarget.dataset.showTestimonial)
    }

    showTestimonial(id) {
        this.elements.testimonials.forEach((testimonial) => {
            testimonial.classList.remove(this.classes.active)

            if (testimonial.dataset.testimonialId === id) {
                testimonial.classList.add(this.classes.active)
            }
        })

        this.setActiveLogo(id)
    }

    setActiveLogo(id) {
        this.elements.logos.forEach((logo) => {
            const parentElement = logo.parentElement
            parentElement.classList.remove(this.classes.active)

            if (logo.dataset.showTestimonial === id) {
                parentElement.classList.add(this.classes.active)
            }
        })
    }
}

const testimonialsList = document.querySelector(SELECTORS.testimonialsList)
const logosList = document.querySelector(SELECTORS.logosList)

if (testimonialsList && logosList) {
    new Testimonials(testimonialsList)
}
