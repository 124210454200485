/**
 * Heavily inspired by Heydon Pickering's Inclusive Components Pattern: https://inclusive-components.design/tabbed-interfaces/
 */
class Tabs {
    activeTab = null // contains the href of the currently active tab
    constructor(tabsElement, contentWrapperElement) {
        // get all the possible tabs as DOM nodes
        this.tabs = Array.from(tabsElement.querySelectorAll('[role="tab"]'))

        // get all the possible panels
        this.panels = Array.from(
            contentWrapperElement.querySelectorAll('[role="tabpanel"]')
        )

        this.init()
    }

    init() {
        // first, determine which tab is initially active and set it as the active tab
        this.setActiveTab(this.findActiveTab())

        // add event listeners to each tab
        this.tabs.forEach((tab) => {
            // listen for the click event on each tab
            tab.addEventListener('click', (event) => {
                const clickedTab = event.target.closest('[role="tab"]')

                if (clickedTab) {
                    this.setActiveTab(clickedTab.id, true)
                }
            })

            // also listen for the keydown event on each tab
            tab.addEventListener('keydown', (event) => {
                let index = this.tabs.indexOf(event.target)

                // discern the direction of the arrow keys and save it as the new index, unless its 'right'
                let direction =
                    event.which === 38 // arrow up
                        ? index - 1
                        : event.which === 40 // arrow down
                        ? index + 1
                        : event.which === 39 // arrow right
                        ? 'right'
                        : null

                // if anything but the 'up' key was pressed
                if (direction !== null) {
                    event.preventDefault()

                    // if the right key was pressed, focus the associated panels content
                    if (direction === 'right') {
                        const associatedPanel = this.getPanelForTab(tab.id)
                        associatedPanel.focus()
                        // if up or down was pressed, set the active tab to the next or previous tab. setActiveTab() will do everything else.
                    } else {
                        const newTab = this.tabs[direction]

                        if (newTab) {
                            this.setActiveTab(newTab.id, true)
                        }
                    }
                }
            })
        })
    }

    setActiveTab(tabId, focusTab = false) {
        // unselect all tabs that are not the new active tab
        this.tabs.forEach((tab) => {
            tab.setAttribute(
                'aria-selected',
                tab.id === tabId ? 'true' : 'false'
            )

            if (tab.id !== tabId) {
                tab.setAttribute('tabindex', '-1')
            } else {
                tab.removeAttribute('tabindex')
                focusTab && tab.focus()
            }
        })

        this.activeTab = tabId

        this.showTabPanel(tabId)
    }

    showTabPanel(tabId) {
        const panelId = tabId.replace(/^tab-/, 'tab-content-')

        // unselect all panels that are not the new active panel
        this.panels.forEach((panel) => {
            if (panel.id !== panelId) {
                panel.hidden = true
                panel.setAttribute('tabindex', '-1')
            } else {
                panel.hidden = false
                panel.removeAttribute('tabindex')
            }
        })

        this.activeTab = panelId
    }

    getPanelForTab(tabId, returnDOM = true) {
        const panelId = tabId.replace(/^tab-/, 'tab-content-')
        const panel = this.panels.find((panel) => panel.id === panelId)

        return returnDOM ? panel : panel.id
    }

    // finds out which of the tabs is the currently active tab and returns its id
    findActiveTab() {
        let matchedTabId = null

        // first, see if the current URL contains a fragment link that can point to any of the tabs.
        this.tabs.forEach((tab) => {
            if (window.location.href.includes(tab.getAttribute('href'))) {
                matchedTabId = tab.id
            }
        })

        // if that wasn't set, look for the aria-selected attribute to determine that it is active.
        !matchedTabId &&
            this.tabs.forEach((tab) => {
                if (tab.getAttribute('aria-selected') === 'true') {
                    matchedTabId = tab.id
                }
            })

        // if nothing was set, assert that the first tab needs to be active.
        matchedTabId = !matchedTabId ? this.tabs[0].id : matchedTabId

        return matchedTabId
    }

    getActiveTab() {
        return this.activeTab
    }
}

const tabsMenu = document.querySelector('[data-tabs-menu]')
const tabsContentWrapper = document.querySelector('[data-tabs-content-wrapper]')

if (tabsMenu && tabsContentWrapper) {
    new Tabs(tabsMenu, tabsContentWrapper)
}
