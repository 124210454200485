// ===================
// show a secret messsage in the console for any
// potential new hires to decipher.
// ===================

function cowsay(msg) {
    const formattedMsg = msg.match(/.{1,25}/g).join('\n')
    // prettier-ignore
    const cow =
        '_________________________\n' +
        `${formattedMsg} \n` +
        '-------------------------\n' +
        '     \\   ^__^\n' +
        '      \\  (oo)\\_______\n' +
        '         (__)\\       )\\/\\\n' +
        '             ||----w |\n' +
        '             ||     ||\n'

    // eslint-disable-next-line no-console
    console.log(cow)
}

const recruitingMessage = `Looking at source code? Maybe you're the kind of person who would enjoy working with us! hello@codista.com`
cowsay(btoa(recruitingMessage))
