const SELECTORS = {
    conversionTrackedElements: '[data-track-conversion]'
}

const usedTriggers = []
function trackConversion(trigger) {
    if (!window.dataLayer) {
        window.dataLayer = []
    }
    if (usedTriggers.includes(trigger)) {
        return
    }
    const triggerName = trigger.dataset.trackConversion || 'default'
    const eventName = `conversion_click_${triggerName}`

    const args = {
        tagName: trigger.tagName,
        text: trigger.innerText
    }
    const data = Object.assign({}, { event: eventName }, args)
    window.dataLayer.push(data)
    usedTriggers.push(trigger)

    // console.log(window.dataLayer)
}

Array.from(
    document.querySelectorAll(SELECTORS.conversionTrackedElements)
).forEach(el => {
    el.addEventListener('click', () => trackConversion(el))
})
