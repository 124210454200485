import Cookies from 'js-cookie'
const SELECTORS = {
    toggleBtn: '.js-darkmode-toggle',
    flickerTarget: '[data-flicker-chars]',
    onSound: '#js-sound-on',
    offSound: '#js-sound-off'
}

class DarkMode {
    constructor() {
        this.isActive = false
        this.cookieTimeoutDays = 30
        this.cookieName = 'darkMode'

        this.toggleBtns = Array.from(
            document.querySelectorAll(SELECTORS.toggleBtn)
        )
        this.flickerTargets = Array.from(
            document.querySelectorAll(SELECTORS.flickerTarget)
        )
        this.onSound = document.querySelector(SELECTORS.onSound)
        this.offSound = document.querySelector(SELECTORS.offSound)

        this.init()
    }

    init() {
        this.isActive = this.getPreference()

        this.toggleBtns.forEach(btn =>
            btn.addEventListener('click', () => {
                this.toggle()
                this.playSound()
            })
        )

        this.setFlicker()
    }

    playSound() {
        const sound = this.isActive ? this.onSound : this.offSound

        sound.currentTime = 0
        sound.play()
    }

    setFlicker() {
        if (!this.flickerTargets.length) {
            return false
        }

        const wrapRandomChars = (str, iterations = 1) => {
            const chars = str.split('')
            const excludedChars = [' ', '-', ',', ';', ':', '(', ')']
            const excludedIndexes = []
            let i = 0

            while (i < iterations) {
                const randIndex = Math.floor(Math.random() * chars.length)
                const c = chars[randIndex]
                if (
                    !excludedIndexes.includes(randIndex) &&
                    !excludedChars.includes(c)
                ) {
                    chars[randIndex] = `<span class="u-flicker">${c}</span>`
                    excludedIndexes.push(randIndex)
                    i++
                }
            }

            return chars.join('')
        }

        this.flickerTargets.forEach(el => {
            const text = el.textContent.trim()
            const { flickerChars } = el.dataset
            const iterations = flickerChars ? parseInt(flickerChars) : undefined

            el.innerHTML = wrapRandomChars(text, iterations)
        })
    }

    getPreference() {
        const cookie = Cookies.get(this.cookieName)
        // const systemPreference = window.matchMedia(
        //     '(prefers-color-scheme: dark)'
        // ).matches


        switch (cookie) {
            case 'true':
                return true
            case 'false':
                return false
            default:
                // return systemPreference
                return false
        }
    }

    toggle(flag) {
        this.isActive = typeof flag === 'boolean' ? flag : !this.isActive
        const theme = this.isActive ? 'dark' : 'light'

        document.documentElement.setAttribute('data-theme', theme)
        Cookies.set(this.cookieName, String(this.isActive), {
            expires: this.cookieTimeoutDays
        })

        this.toggleBtns.forEach(btn => {
            if (btn.nodeName.toLowerCase() === 'button') {
                btn.setAttribute('aria-checked', String(this.isActive))
            }
        })
    }
}

if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
    window.darkmode = new DarkMode()
}
