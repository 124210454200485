/**
 * We wanted to be able to animate <details>'s open/close behaviour. So while this looks at all <details>, it only really is relevant to <details data-animate>; Inspired by: https://css-tricks.com/how-to-animate-the-details-element-using-waapi/
 */

const SELECTORS = {
    details: '.js-details'
}

class Details {
    elements = {
        root: null,
        summary: null,
        content: null
    }

    animationDuration = 350
    animationTimingFunction = 'ease-in-out'

    isClosing = false
    isOpening = false

    animation = null

    isOpen = null
    useAnimation = false

    constructor(element) {
        this.elements.root = element
        this.elements.summary = this.elements.root.querySelector('summary')
        this.elements.content = this.elements.root.querySelector('summary + *')

        this.isOpen = this.elements.root.open

        this.useAnimation = this.elements.root.hasAttribute('data-animate')

        this.elements.summary.addEventListener(
            'click',
            this.handleClick.bind(this)
        )
    }

    handleClick(e) {
        e.preventDefault()

        this.elements.root.style.overflow = 'hidden'

        if (this.isClosing || !this.isOpen) {
            this.open()
        } else if (this.isOpening || this.isOpen) {
            this.close()
        }
    }

    open() {
        if (this.useAnimation) {
            this.elements.root.style.height = `${this.elements.root.offsetHeight}px`

            this.elements.root.open = true

            window.requestAnimationFrame(() => this.animateExpand())
        } else {
            this.elements.root.open = true
            this.isOpen = true
        }
    }

    close() {
        if (this.useAnimation) {
            this.elements.root.style.height = `${this.elements.root.offsetHeight}px`

            window.requestAnimationFrame(() => this.animateShrink())
        } else {
            this.elements.root.open = false
            this.isOpen = false
        }
    }

    animateExpand() {
        this.isOpening = true

        const startheight = `${this.elements.root.offsetHeight}px`
        const endheight = `${
            this.elements.summary.offsetHeight +
            this.elements.content.offsetHeight
        }px`

        if (this.animation) {
            this.animation.cancel()
        }

        this.animation = this.elements.root.animate(
            {
                height: [startheight, endheight]
            },
            {
                duration: this.animationDuration,
                easing: this.animationTimingFunction
            }
        )

        this.animation.onfinish = () => this.animationFinished(true)
        this.animation.oncancel = () => (this.isOpening = false)
    }

    animateShrink() {
        this.isClosing = true

        const startheight = `${this.elements.root.offsetHeight}px`
        const endheight = `${this.elements.summary.offsetHeight}px`

        if (this.animation) {
            this.animation.cancel()
        }

        this.animation = this.elements.root.animate(
            {
                height: [startheight, endheight]
            },
            {
                duration: this.animationDuration,
                easing: this.animationTimingFunction
            }
        )

        this.animation.onfinish = () => this.animationFinished(false)
        this.animation.oncancel = () => (this.isClosing = false)
    }

    animationFinished(isOpen) {
        this.elements.root.open = isOpen
        this.isOpen = isOpen
        this.animation = null

        this.isClosing = false
        this.isOpening = false

        this.elements.root.style.height = this.elements.root.style.overflow = ''
    }
}

if (document.querySelector(SELECTORS.details)) {
    const details = Array.from(document.querySelectorAll(SELECTORS.details))
    details.forEach((detail) => new Details(detail))
}
