import LazyLoad from 'vanilla-lazyload'

const SELECTORS = {
    image: 'img[data-src]'
}
const CLASSES = {
    loaded: 'is-loaded'
}

export const lazyloadInstance = new LazyLoad({
    elements_selector: SELECTORS.image,
    class_loaded: CLASSES.loaded,
    thresholds: '100% 0px'
})
